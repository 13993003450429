import { route } from 'nextjs-routes';
import { CtaSection } from '~/components/CtaSection';
import { HeroSection } from '~/components/HeroSection';
import { StatsSection } from '~/components/StatsSection';

export default function Page() {
  return (
    <>
      <HeroSection
        title={
          <>
            <span className="font-light">Stock Rankings</span>
          </>
        }
        cta1Label="View Rankings"
        cta2Label=""
        href1={route({ pathname: '/rankings/stocks/cannabis' })}
        href2=""
        subtitle="We rank the top cannabis stocks based on their financials, performance, and valuation. Our rankings are powered by Stock Unlock, an investment research platform trusted by thousands of investors."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        leftImage="https://images.unsplash.com/photo-1542744173-8e7e53415bb0?crop=entropy&fit=crop&h=800&w=1280"
        rightImage="https://images.unsplash.com/photo-1554232456-8727aae0cfa4?crop=entropy&fit=crop&h=800&q=80&w=600"
      />
      <CtaSection
        title={<>Powered by Stock Unlock!</>}
        href="https://stockunlock.com?su_affiliate_source=cannastockrankings"
        target="_blank"
        ctaLabel="Sign up for Stock Unlock"
        subtitle="Stock Unlock is an investment research platform that powers our website and our blog. Sign up now, no credit card required!"
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      {/* <NewsletterSection
        title="Subscribe to our blog"
        subtitle="Join us and receive updates to the rankings on a quarterly basis. We respect your privacy and will never share your email address with anyone."
        strapline=""
        size="lg"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      /> */}
      <StatsSection
        strapline="Stock Unlock"
        title="Trusted by thousands of investors"
        subtitle="The premier stock research platform, trusted by Daniel Pronk and other prominent investors."
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        stats={[
          {
            title: 'Investors',
            count: '20,000+',
          },
          {
            title: 'Stocks',
            count: '10,000+',
          },
          {
            title: 'ETFs',
            count: '5,000+',
          },
        ]}
      />
    </>
  );
}
