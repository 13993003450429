import { ArrowRightIcon } from '@heroicons/react/24/solid';
import Link from 'next/link';
import { Button } from '~/components/Button';
import Section from '~/components/Section';
import SectionHeader from '~/components/SectionHeader';

export function CtaSection(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-10 container">
        <div className="text-center">
          <img
            className="w-10 h-[52px] relative inline-flex items-center justify-center mb-10 mx-auto"
            src="/images/StockUnlock_logoPrimary.png"
            alt="Stock Unlock Logo"
          />
          <SectionHeader title={props.title} subtitle={props.subtitle} strapline={props.strapline} />
          <div className="text-center">
            <Link href={props.href || '/'} passHref={true} target={props.target || '_self'}>
              {/* @ts-ignore */}
              <Button size="xl" endIcon={<ArrowRightIcon className="opacity-70 inline-block w-5 h-5" />}>
                {props.ctaLabel || <>Let&apos;s get started</>}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </Section>
  );
}
